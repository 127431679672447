export const trackPageLoad = () => {
  if (window.gtag) {
    window.gtag('event', 'open', {
      event_category: 'm_page',
      event_label: 'main',
    })
  }
}

export const trackWidgetOpen = (label) => {
  if (window.gtag) {
    window.gtag('event', 'open', {
      event_category: 'widget',
      event_label: label,
    })
  }
}

export const trackSubscribe = (label) => {
  if (window.gtag) {
    window.gtag('event', 'sub', {
      event_category: 'widget',
      event_label: label,
    })
  }
}

export const trackEmailSent = () => {
  if (window.gtag) {
    window.gtag('event', 'sent', {
      event_category: 'uploaded',
      event_label: 'email',
    })
  }
}

export const trackLicensePlateInput = () => {
  if (window.gtag) {
    window.gtag('event', 'entered', {
      event_category: 'm_input',
      event_label: 'license_plate',
    })
  }
}

export const trackSearchSuccess = () => {
  if (window.gtag) {
    window.gtag('event', 'event1', {
      event_category: 'm_result ',
      event_label: true,
    })
  }
}

export const trackSearchFail = () => {
  if (window.gtag) {
    window.gtag('event', 'event1', {
      event_category: 'm_result ',
      event_label: false,
    })
  }
}

export const trackDocSave = (label) => {
  if (window.gtag) {
    window.gtag('event', 'save', {
      event_category: 'documents ',
      event_label: label || 'docs',
    })
  }
}

export const trackDocChoice = (label) => {
  if (window.gtag) {
    window.gtag('event', 'choice', {
      event_category: 'documents',
      event_label: label,
    })
  }
}

export const trackDocRestore = () => {
  if (window.gtag) {
    window.gtag('event', 'restore', {
      event_category: 'documents',
      event_label: 'code',
    })
  }
}
