import axios from 'axios'

class API {
  constructor() {
    this.http = axios.create({
      baseURL: 'https://osago.one'
    })
  }

  getPolicyInfo(params = {}) {
    return this.http.get('/api/mobile/getpolicyinfo', {
      params
    })
  }

  getPolicyDate(params = {}) {
    return this.http('/api/mobile/getpolicydate', {
      params
    })
  }

  getProlongCode(params = {}) {
    return this.http.get('/api/mobile/getProlongCode', {
      params
    })
  }

  getProlongLink(params = {}) {
    return this.http.get('/api/mobile/getProlongLink', {
      params
    })
  }

  checkDocuments(params = {}) {
    return this.http.get('/api/mobile/checkDocuments', {
      params
    })
  }
}

export default new API()  